// BASE
* {
    font-family: 'Open Sans', sans-serif;
}

body {
    margin: 0;
    padding: 0;
    background-color: #FFFFFF;
}

:root {
    --accent: #000099;
    --lightblue: #3399ff;
    --whitelight: #e8f1f2;
    --lightgray: #E4E6E4;
    --colorBackground: #d8eefe;
}

a {
    text-decoration: none !important;
}

h1, h2, h3, h4, .h1, .h2, .h3, .h4 {
    font-family: 'Montserrat', sans-serif;
}

strong {
    font-weight: bold;
}

.text-accent {
    color: var(--accent);
}

.text-lightblue {
    color: var(--lightblue);
}

.text-card-desktop {
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: var(--lightgray);
    color: var(--whitelight);
}

.text-whitelight {
    color: var(--whitelight);
}

.text-link {
    color: var(--lightblue);
}

.text-link:hover {
    color: var(--accent);
    text-decoration: none;
}

.text-colorbackground {
    color: var(--colorBackground);
}

.bg-whitelight {
    background-color: var(--whitelight);
}

.bg-accent {
    background-color: var(--accent);
}

.bg-lightblue {
    background-color: var(--lightblue);
}

.bg-lightgray {
    background-color: var(--lightgray);
}

.bg-colorbackground {
    background-color: var(--colorBackground);
}

.rotate{
    -moz-transition: all 0.4s linear;
    -webkit-transition: all 0.4s linear;
    transition: all 0.4s linear;
}

.rotate.down{
    -ms-transform: rotate(340deg);
    -moz-transform: rotate(340deg);
    -webkit-transform: rotate(340deg);
    transform: rotate(340deg);
}

// NAVBAR
#nav2 {
    transition: 0.4s;
}

.border-navbar {
    border-bottom: 2px solid var(--accent);
}

.nav-text-whitelight {
    color: var(--whitelight);
    font-size: 1rem;
    font-weight: 600;
    padding-right: 0.8rem;
    padding-left: 0.8rem;
} 

.nav-text-whitelight:hover {
    color: var(--whitelight);
    text-decoration: none;
}

.nav-hover {
    transition: 0.4s;
    border-bottom: 2px solid transparent;
    border-radius: 2px;
    margin: 0px;
}

.nav-hover:hover {
    border-bottom: 2px solid var(--whitelight);
    margin: 0px;
}

.nav-text-lightblue {
    color: var(--lightblue);
    padding-left: 0.4rem;
}

.nav-text-lightblue:hover {
    color: var(--accent);
    text-decoration: none;
}

.navbar-toggler:focus {
    box-shadow: none;
}


// HOME
.carousel-item {
    height: 80vh;
    min-height: 350px;
    background: no-repeat center center scroll;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}


.masthead-mozia {
    height: 70vh;
    background-image: url('../../public/images/IMG_1046-min.jpeg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.masthead-tour {
    height: 70vh;
    background-image: url('../../public/images/stagnone_alto-min.webp');
    background-size: cover;
    background-position: top;
    background-repeat: no-repeat;
}

.masthead-sunset {
    height: 70vh;
    background-image: url('../../public/images/IMG_4274-min.webp');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.masthead-404 {
    height: 80vh;
    background-image: linear-gradient(black, rgba(255,255,255,0.1), black), url('../../public/images/ship-1366926_1920-min.webp');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.masthead-503 {
    height: 100vh;
    background-image: url('../../public/images/jack_orizzonte.webp');
    background-size: cover;
    background-position: top;
    background-repeat: no-repeat;
}

.masthead-500 {
    height: 80vh;
    background-image: url('../../public/images/jack_pensa.webp');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.my-button {
    background-color: var(--accent);
    color: var(--whitelight);
    border: 1px solid var(--whitelight);
}

.my-button:hover {
    background-color: white;
    color: var(--lightblue);
    border: 1px solid var(--lightblue);
    text-decoration: none !important;
    transition: 0.4s all;
}

// first try card
.card-service-mobile {
    background-color: var(--colorBackground);
    box-shadow: rgba(163, 177, 198, 0.5) -7px -7px 14px 0px, rgb(255, 255, 255) 7px 7px 14px 0px;
    -webkit-box-shadow: rgba(163, 177, 198, 0.5) -7px -7px 14px 0px, rgb(255, 255, 255) 7px 7px 14px 0px;
    border-radius: 38px;
    -webkit-border-radius: 38px;
    box-sizing: border-box;
    overflow: hidden;
}

.card-service-mobile img {
    margin: 0;
    width: 320px;
    height: 244px;
    object-fit: cover;
    display: block;
}

// second try card
.card-service {
    width: 280px;
    height: 330px;
    padding: 0;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.1);
    border-radius: 8px;
    box-sizing: border-box;
    overflow: hidden;
}

.card-service img {
    margin: 0;
    width: 300px;
    height: 224px;
    object-fit: cover;
    display: block;
}

.hr {
    width: 80px;
    height: 2px;
    margin-top: 0px;
    background-color: var(--lightblue);
    border-radius: 3px;
}

/* .table th, .table td {
    text-align: center;
    vertical-align: center;
} */

.card {
    height: 150px;
}

.card:hover {
    transition: 0.4s;
    box-shadow: 0 0 11px var(--lightgray); 
}

.list-unstyled {
    padding-left: 0;
    list-style: none;
}

.social-footer {
    color: var(--whitelight);
}

.social-footer:hover {
    color: var(--accent);
}

.text-footer {
    color: var(--whitelight);
}

.text-footer:hover {
    color: var(--whitelight);
}

.text-link-footer {
    color: var(--whitelight);
}

.text-link-footer:hover {
    text-decoration: none;
    color: var(--whitelight);
    border-bottom: 1px solid var(--whitelight);
}

.hr-footer {
    width: 60px;
    height: 2px;
    margin-top: 0px;
    margin-left: 0px;
    background-color: var(--whitelight);
    border-radius: 3px;
}

.photo-gallery {
    color:#313437;
    background-color: var(--colorBackground);
}

.photo-gallery p {
    color:#7d8285;
}

.photo-gallery h2 {
    font-weight:bold;
    margin-bottom:10px;
    padding-top:40px;
}

@media (max-width:767px) {
    .photo-gallery h2 {
        margin-bottom:25px;
        padding-top:25px;
        font-size:24px;
    }
}

.photo-gallery .intro {
    font-size:16px;
    max-width:500px;
    margin:0 auto 40px;
}

.photo-gallery .intro p {
    margin-bottom:0;
}

.photo-gallery .photos {
    padding-bottom:20px;
}

.photo-gallery .item {
    padding-bottom:30px;
}

@media (max-width: 785px) {
    #info-bar {
        display: none !important;
    }
}
/* 991 */
@media (min-width: 786px) {
    #info-collapse {
        display: none !important;
    }
}

.languages {
    background-color: transparent;
    border: none;
}



/* SERVICE HOME */
.wrap
{
    margin:50px auto 0 auto;
    width:100%;
    display:flex;
    align-items:space-around;
    max-width:1000px;
}

/* .tile
{
    width:350px;
    height:300px;
    margin:10px;
    background-color: rgba(51, 153, 255, 0.5);
    display:inline-block;
    background-size:cover;
    position:relative;
    cursor: pointer;
    transition: all 0.4s ease-out;
    box-shadow: 0px 35px 77px -17px rgba(0,0,0,0.44);
    overflow:hidden;
    color:white;
    font-family:'Roboto';
} */

.card2 {
    position: relative;
    height: 280px;  
    transition: 0.4s;
}

.card2 img
{
    height:100%;
    width:100%;
    position:absolute;
    top:0;
    left:0;
    z-index:0;
    transition: all 0.4s ease-out;
}
.card2 .text
{
    /*   z-index:99; */
    position:absolute;
    padding:30px;
    height:calc(100% - 60px);
}
.card2 h2
{ 
    font-weight:300;
    margin:0;
    text-shadow: 2px 2px 10px rgba(0,0,0,0.3);
}
/* .card2 h2
{
    font-weight:100;
    margin:20px 0 0 0;
    font-style:italic;
    transform: translateX(200px);
} */

.card2 p
{
    font-weight:300;
    margin:20px 0 0 0;
    line-height: 25px;
    /*   opacity:0; */
    transform: translateY(50px);
}

.card2 a {
    font-weight:300;
    margin:20px 0 0 0;
    line-height: 25px;
    /*   opacity:0; */
    transform: translateY(50px);
}

.animate-text
{
    opacity:0;
    transition: ease-in 0.3s;
}
.card2:hover
{
    /*   background-color:#99aeff;
    rgba(51, 153, 255, 0.8) */
    box-shadow: 0px 35px 77px -17px rgba(0,0,0,0.64);
    transform:scale(1.05);
    background-color: var(--colorBackground);
}
.card2:hover img
{
    opacity: 0.2;
}

.card2:hover h3 {
    color: black;
}

.card2:hover .animate-text
{
    transform:translateX(0);
    opacity:1;
}

.width-auto {
    width: auto;
}

.text-lg {
    font-size: 2rem;
}

.carousel-indicators li {
    border: none;
    background: #ccc;
}

.carousel-indicators li.active {
    background: var(--lightblue);
}

.blockquote-custom {
    position: relative;
    font-size: 1.1rem;
}

.blockquote-custom-icon {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: -25px;
    left: 50px;
}

.btn-service {
    background-color: var(--whitelight);
    border: 1px solid var(--lightblue);
    color: var(--lightblue);
}

.btn-service:hover {
    background-color: var(--lightblue);
    border: 1px solid var(--whitelight);
    color: var(--whitelight) !important;
}

.first-header-slide {
    background-image: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), url('../../public/images/mozia/IMG_0513-min.webp');
}

.second-header-slide {
    background-image: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), url('../../public/images/other/stagnone_alto-min.webp');
    background-position: top;
}

.third-header-slide {
    background-image: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), url('../../public/images/sunset/IMG_4274-min.webp');
}

.caption-animated {
    opacity: 0;
    bottom: 0px;
}

.carousel-item.active .caption-animated {
    transition: 1s;
    bottom: 40px;
    opacity: 1;
}

.text-header {
    font-size: 42px;
    color: white;
    font-weight: bold;
}

#header {
    position: relative;
    background-color: black;
    height: 75vh;
    min-height: 25rem;
    width: 100%;
    overflow: hidden;
}

#header video {
    position: absolute;
    top: 50%;
    left: 50%;
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    z-index: 0;
    -ms-transform: translateX(-50%) translateY(-50%);
    -moz-transform: translateX(-50%) translateY(-50%);
    -webkit-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
}

#header .container {
    position: relative;
    z-index: 2;
}

#header .overlay {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: black;
    opacity: 0.5;
    z-index: 1;
}

@media (pointer: coarse) and (hover: none) {
    #header {
        background: url('../../public/images/sunset/IMG_4274-min.webp');
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
    }
    #header video {
        display: none;
    }
}

.js-cookie-consent {
    position: fixed;
    bottom: 0px;
    padding: 30px;
    text-align: center;
    width: 100%;
    z-index: 9999;
    background-color: var(--colorBackground);
    border: 1px solid var(--lightblue);
}

.js-cookie-consent-agree {
    margin-left: 15px;
    background-color: white;
    border: 1px solid var(--lightblue);
    border-radius: 5px;
    color: var(--lightblue);
}

.js-cookie-consent-agree:hover {
    transition: 0.4s;
    background-color: var(--lightblue);
    border: 1px solid white;
    color: white;
}

.container_foto {
	padding: 0;
	overflow: hidden;
	max-width: 350px;
	margin: 5px;
}

.container_foto article {
	padding: 10%;
	position: absolute;
	bottom: 0;
	z-index: 1;
	-webkit-transition: all 0.4s ease;
	-moz-transition: all 0.4s ease;
	-o-transition: all 0.4s ease;
	-ms-transition: all 0.4s ease;
	transition: all 0.4s ease;
}

.container_foto h2 {
	color: #fff;
	font-weight: 800;
	font-size: 25px;
}

.container_foto img {
	width: 100%;
	top: 0;
	left: 0;
	-webkit-transition: all 0.4s ease;
	-moz-transition: all 0.4s ease;
	-o-transition: all 0.4s ease;
	-ms-transition: all 0.4s ease;
	transition: all 0.4s ease;
}

.ver_mas {
	background-color: var(--colorBackground);
	position: absolute;
	width: 100%;
	height: 70px;
	bottom: 0;
	z-index: 1;
	opacity: 0;
	transform: translate(0px, 70px);
	-webkit-transform: translate(0px, 70px);
	-moz-transform: translate(0px, 70px);
	-o-transform: translate(0px, 70px);
	-ms-transform: translate(0px, 70px);
	-webkit-transition: all 0.2s ease-in-out;
	-moz-transition: all 0.2s ease-in-out;
	-o-transition: all 0.2s ease-in-out;
	-ms-transition: all 0.2s ease-in-out;
	transition: all 0.2s ease-in-out;
}

.ver_mas2 {
	position: absolute;
	width: 100%;
	height: 70px;
	bottom: 0;
	z-index: 1;
	opacity: 0;
	transform: translate(0px, 70px);
	-webkit-transform: translate(0px, 70px);
	-moz-transform: translate(0px, 70px);
	-o-transform: translate(0px, 70px);
	-ms-transform: translate(0px, 70px);
	-webkit-transition: all 0.2s ease-in-out;
	-moz-transition: all 0.2s ease-in-out;
	-o-transition: all 0.2s ease-in-out;
	-ms-transition: all 0.2s ease-in-out;
	transition: all 0.2s ease-in-out;
}


/*hovers*/

.container_foto:hover {
    background-color: var(--colorBackground);
}


.container_foto:hover img {
	opacity: 0.2;
	transform: scale(1);
}

.container_foto:hover h2 {
    color: black;
    transition: 0.4s;
}

.container_foto:hover article {
	transform: translate(2px, -145px);
	-webkit-transform: translate(2px, -145px);
	-moz-transform: translate(2px, -145px);
	-o-transform: translate(2px, -145px);
	-ms-transform: translate(2px, -145px);
}

.container_foto:hover .ver_mas{
	transform: translate(0px, 0px);
	-webkit-transform: translate(0px, 0px);
	-moz-transform: translate(0px, 0px);
	-o-transform: translate(0px, 0px);
	-ms-transform: translate(0px, 0px);
	opacity: 1;
}

.container_foto:hover .ver_mas2{
	transform: translate(0px, 50px);
	-webkit-transform: translate(0px, 50px);
	-moz-transform: translate(0px, 50px);
	-o-transform: translate(0px, 50px);
	-ms-transform: translate(0px, 50px);
	opacity: 1;
}